import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../models/api-response.interface';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GanttService {

  private baseUrl = `${environment.apiUrl}/${environment.projectCode}/${environment.apiVersion}`;
  private taskUrl = `${this.baseUrl}/tasks`;
  private resourceUrl = `${this.baseUrl}/resources`;
  private projectUrl = `${this.baseUrl}/projects`;
  private projectTypeUrl = `${this.baseUrl}/project-types`;
  private projectResourceUrl = `${this.baseUrl}/project-resources`;
  private holidayUrl = `${this.baseUrl}/holidays`;
  private eventUrl = `${this.baseUrl}/event-marks`;
  private workDayUrl = `${this.baseUrl}/work-days`;

  constructor(
    private http: HttpClient
  ) {
    console.log(environment.apiUrl);
  }

  // 沒限制筆數拿全部
  getTasksByProjectIds(project_uuids: string[], is_milestone: boolean = false): Observable<ApiResponse> {
    let body: any = {
      filter: {
        is_milestone: is_milestone
      },
      projects: project_uuids
    }
    return this.http.post<any>(`${this.taskUrl}/get-by-projects`, body);
  }

  getTaskSelection(): Observable<ApiResponse> {
    // let obj: any = {
    //   filter: {
    //     is_milestone: false,
    //   }
    // };
    return this.http.get<any>(`${this.taskUrl}/no-pagination/no-sub-filter`);
  }

  getTask(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.taskUrl}/${id}`);
  }

  addTask(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.taskUrl}`, body);
  }

  importTask(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.taskUrl}/import`, body);
  }

  editTask(id: string, body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${this.taskUrl}/${id}`, body);
  }

  editAllTasks(body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${this.taskUrl}/update-all`, body);
  }

  removeTask(body: any): Observable<ApiResponse> {
    return this.http.delete<any>(`${this.taskUrl}`, {body: body});
  }

  // task end

  // resource start
  // getResources(): Observable<ApiResponse> {
  //   return this.http.get<any>(`${this.resourceUrl}?page=1&limit=20`);
  // }

  getResources(): Observable<ApiResponse> {
    return this.http.get<any>(`${this.resourceUrl}/no-pagination`);
  }

  getResourcesFilter(search?: string, group?: any[], field = 'resource_group', direction = 'asc', page = 1, limit = 20): Observable<ApiResponse> {
    let body = {
      filter: {
        resource_name: search ? search : null,
        resource_groups: group ? group : null,
        email: search ? search : null,
        phone: search ? search : null,
      },
      sort: {
        field: field,
        direction: direction
      },
    }
    console.log('filter', body.filter, 'sort:', body.sort)
    return this.http.post<any>(`${this.resourceUrl}/list?page=${page}&limit=${limit}`, body);
  }

  getResource(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  addResource(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.resourceUrl}`, body);
  }

  importResource(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.resourceUrl}/import`, body);
  }


  editResource(id: string, body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${this.resourceUrl}/${id}`, body);
  }

  removeResource(id: string): Observable<ApiResponse> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  // resource end

  // project start

  getFilterProjects(status?: any[], type?: any[], search?: string, page: number = 1, limit: number = 20): Observable<ApiResponse> {
    let body = {
      filter: {
        status: status ? status : null,
        type: type ? type : null,
        client: search ? search : null,
        project_name: search ? search : null,
        manager: search ? search : null,
        code: search ? search : null
      }
    }
    return this.http.post<any>(`${this.projectUrl}/list?page=${page}&limit=${limit}`, body);
  }

  getProjects(): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectUrl}/no-pagination`);
  }

  getProject(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectUrl}/${id}`);
  }

  addProject(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.projectUrl}`, body);
  }

  editProject(id: string, body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${this.projectUrl}/${id}`, body);
  }

  removeProject(id: string): Observable<ApiResponse> {
    return this.http.delete<any>(`${this.projectUrl}/${id}`);
  }

  getProjectTypes(): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectTypeUrl}/no-pagination`);
  }

  getProjectTypesList(page = 1, limit = 20): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectTypeUrl}?page=${page}&limit=${limit}`);
  }

  getProjectType(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectTypeUrl}/${id}`);
  }

  addProjectType(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.projectTypeUrl}`, body);
  }

  editProjectType(id: string, body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${this.projectTypeUrl}/${id}`, body);
  }

  removeProjectType(id: string): Observable<ApiResponse> {
    return this.http.delete<any>(`${this.projectTypeUrl}/${id}`);
  }

  // project_resource
  getProjectResources(): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectResourceUrl}`);
  }

  // addProjectResource(body: any): Observable<ApiResponse> {
  //   return this.http.post<any>(`${this.projectResourceUrl}`, body);
  // }

  getProjectResourcesByProjectId(ids: string[]): Observable<ApiResponse> {
    let obj = {
      projects: ids
    }
    return this.http.post<any>(`${this.projectResourceUrl}/get-by-project`, obj);
  }

  getProjectResource(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectResourceUrl}/${id}`);
  }

  // removeProjectResource(id: string): Observable<ApiResponse> {
  //   return this.http.delete<any>(`${this.projectResourceUrl}/${id}`);
  // }
  //
  // editProjectResource(id: string, body: any): Observable<ApiResponse> {
  //   return this.http.patch<any>(`${this.projectResourceUrl}/${id}`, body);
  // }


  // project end

  // holiday
  getHolidays(): Observable<ApiResponse> {
    return this.http.get<any>(`${this.holidayUrl}/no-pagination`);
  }

  getHolidaysList(page = 1, limit = 20): Observable<ApiResponse> {
    return this.http.get<any>(`${this.holidayUrl}?page=${page}&limit=${limit}`);
  }

  getHoliday(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.holidayUrl}/${id}`);
  }

  addHoliday(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.holidayUrl}`, body);
  }

  editHoliday(id: string, body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${this.holidayUrl}/${id}`, body);
  }

  removeHoliday(id: string): Observable<ApiResponse> {
    return this.http.delete<any>(`${this.holidayUrl}/${id}`);
  }

  // event

  addEvent(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.eventUrl}`, body);
  }

  editEvent(id: string, body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${this.eventUrl}/${id}`, body);
  }

  removeEvent(id: string): Observable<ApiResponse> {
    return this.http.delete<any>(`${this.eventUrl}/${id}`);
  }

  // work day
  getWorkDays(): Observable<ApiResponse> {
    return this.http.get<any>(`${this.workDayUrl}/no-pagination`);
  }

  getWorkDay(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.workDayUrl}/${id}`);
  }

  addWorkDay(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${this.workDayUrl}`, body);
  }

  editWorkDay(id: string, body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${this.workDayUrl}/${id}`, body);
  }

  removeWorkDay(id: string): Observable<ApiResponse> {
    return this.http.delete<any>(`${this.workDayUrl}/${id}`);
  }
  checkEditer(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectUrl}/check-edit/${id}`);
  }

  canceEditer(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${this.projectUrl}/cancel-edit/${id}`);
  }
}
