<div *ngIf="isLoading" class="loading-screen">
  <span class="loader"></span>
</div>
<div class="h-screen w-screen flex justify-content-center align-items-center" *ngIf="!isLoading">
  <p-card styleClass="pt-5 px-5 vw-30">
    <ng-template pTemplate="header">
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
      <span class="flex justify-content-center font-bold text-4xl">電子郵件已驗證</span>
    </ng-template>
    <div class="grid flex justify-content-center">
      <div class="col-12 flex justify-content-center">
        <p-button label="返回登入頁" icon="pi pi-arrow-right" iconPos="right" routerLink="/login"
                  styleClass="p-button-rounded p-button-secondary p-button-outlined"></p-button>
      </div>
    </div>
  </p-card>
</div>
