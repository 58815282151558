import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MainComponent } from './views/main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorComponent } from './views/error/error.component';
import { SharedModule } from './shared/shared.module';
import { MessageService } from 'primeng/api';
import { ToolbarService, PdfExportService, SelectionService } from '@syncfusion/ej2-angular-gantt';
import { LoginComponent } from './components/login/login.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './components/register/register.component';
import { ActivateComponent } from './components/activate/activate.component';
import { EmailVerifiedSuccessComponent } from './components/email-verified-success/email-verified-success.component';
import { ExpiredComponent } from './components/expired/expired.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ErrorComponent,
    LoginComponent,
    PasswordResetComponent,
    RegisterComponent,
    ActivateComponent,
    EmailVerifiedSuccessComponent,
    ExpiredComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    MessageService,
    ToolbarService,
    PdfExportService,
    SelectionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
