import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuModule, ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {GanttAllModule, GanttModule} from '@syncfusion/ej2-angular-gantt';
import {ButtonModule, CheckBoxAllModule, SwitchAllModule} from '@syncfusion/ej2-angular-buttons';
import {NumericTextBoxAllModule, TextBoxAllModule, UploaderModule} from '@syncfusion/ej2-angular-inputs';
import {DropDownListAllModule, DropDownListModule, MultiSelectAllModule} from '@syncfusion/ej2-angular-dropdowns';
import {DropDownButtonModule} from '@syncfusion/ej2-angular-splitbuttons';
import {GridAllModule} from '@syncfusion/ej2-angular-grids';
import { DialogModule,TooltipModule } from '@syncfusion/ej2-angular-popups';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    GanttModule,
    MenuModule,
    ToolbarModule,
    CheckBoxAllModule,
    SwitchAllModule,
    TextBoxAllModule,
    NumericTextBoxAllModule,
    MultiSelectAllModule,
    DropDownListModule,
    DropDownListAllModule,
    DropDownButtonModule,
    ButtonModule,
    GridAllModule,
    UploaderModule,
    GanttAllModule,
    DialogModule,
    TooltipModule
  ]
})
export class Ej2Module {
}
