import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, retry, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {TokenStorageService} from "./token-storage.service";
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {Router} from "@angular/router";

const TOKEN_HEADER_KEY = 'Authorization' // 定義 token 的 header key

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  private isRefreshing = false; // 用於標記是否正在刷新 token
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null); // 用於在刷新 token 時發送新的 token

  constructor(
    private authServ: AuthService, // 注入 AuthService
    private storageServ: TokenStorageService, // 注入 TokenStorageService
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 在每個請求發送前，將 token 加入到請求的 header 中
    if (!request.headers.has('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.storageServ.getToken()}`
        }
      });
    }
    // 處理請求，並在接收到 401 錯誤時嘗試刷新 token
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (error instanceof HttpErrorResponse && !request.url.includes('login') && error.status === 401) {
        return this.handle401Error(request, next) as Observable<HttpEvent<any>>;
      }
      return throwError(error);
    }))
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // 如果請求的 URL 包含 'refresh'，則登出
    if (request.url.includes('refresh')) {
      this.authServ.logout();
    }
    // 如果不在刷新 token，則嘗試刷新 token
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.storageServ.getRefreshToken();
      if (token) {
        // 如果存在 refresh token，則嘗試刷新 token
        return this.authServ.refreshToken(token).pipe(retry(3), tap(res => {
            console.log('refreshToken') // 輸出刷新 token 的結果
          }),
          switchMap((token: any) => {
            this.isRefreshing = false; // 將刷新狀態設為 false
            this.storageServ.saveToken(token.body.access_token); // 儲存新的 access token
            this.refreshTokenSubject.next(token.body.access_token); // 發送新的 access token
            // 使用新的 access token 重新發送請求
            return next.handle(this.addTokenHeader(request, token.body.access_token)).pipe(tap());
          }),
          catchError((err) => {
            this.isRefreshing = false; // 將刷新狀態設為 false
            if (err.status === 401) {
              this.authServ.logout(); // 如果刷新 token 失敗，則登出
            }
            console.log(err) // 輸出錯誤訊息
            return throwError(err); // 將錯誤拋出，讓上層處理
          })
        );
      } else if (request.url.includes('reset-password')) {
        this.router.navigate(['/password_reset']);
      } else if (request.url.includes('/enable')) {
        this.router.navigate(['/expired/activate']);
      } else if (request.url.includes('/current-user')) {
        this.router.navigate(['/expired/user']);
      } else {
        console.log('else');
      }
    }
    // 如果正在刷新 token，則等待新的 token 並重新發送請求
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token))),
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    // 將 token 加入到請求的 header 中
    return request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, token)});
  }

}
