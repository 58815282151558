import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-email-verified-success',
  templateUrl: './email-verified-success.component.html',
  styleUrls: ['./email-verified-success.component.scss']
})
export class EmailVerifiedSuccessComponent implements OnInit{
  token: any;
  userName!: string;
  isLoading: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private authServ: AuthService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
  }
  ngOnInit() {
    if (this.token) {
      this.authServ.verifyEmail(this.token).subscribe({
        next: res => {
          this.isLoading = false;
          console.log('res', res);
        },
        error: err => {
          console.log('err', err);
        }
      });
      this.authServ.getCurrentUser(this.token).subscribe({
        next: res => {
          this.userName = res.body.user_name;
        },
        error: err => {
          console.log('err', err);
          if (err.status === 401) {

          }
        }
      });
    }
  }
}
