import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from "../../services/auth.service";
import {Injectable} from '@angular/core';
import {TokenStorageService} from "../../services/token-storage.service";

@Injectable({
  providedIn: 'root',
})
export class UserInfoGuard implements CanActivate {
  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.tokenStorage.getToken() && this.tokenStorage.getRefreshToken()) {
      return true;
    } else {
      return this.router.createUrlTree(['/login']);
    }
  }
}
