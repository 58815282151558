<p-toast></p-toast>
<div *ngIf="loading" class="loading-screen">
  <span class="loader"></span>
</div>
<div class="box" (keydown.enter)="login()">
  <div class="box-form">
    <div class="box-login-tab"></div>
    <div class="box-login-title grid">
      <span class="text-cyan-900 font-bold pi pi-user flex justify-content-center mt-3 pr-0 col-6"></span>
      <h1 class="text-cyan-900 font-bold flex justify-content-start mt-3 pl-0 col">登入</h1>
      <h1 class="text-cyan-900 font-bold flex justify-content-start mt-3 pl-0 col"></h1>
    </div>
    <div class="box-login">
      <div class="grid mt-3">
        <div class="ml-auto mr-3">
          <p-button icon="text-cyan-900 text-xs pi pi-ellipsis-h" (click)="openLoginInfo()"
                    styleClass="p-button-sm p-button-secondary p-button-text b-form"></p-button>
        </div>
      </div>
      <form [formGroup]="login_form" id="login_form">
        <div class="grid flex justify-content-center mt-2">
          <div class="flex flex-column col-10 pt-0 gap-2">
            <h1 class="text-cyan-900 font-bold text-lg text-left mb-5">{{ title }}</h1>
            <label class="text-cyan-900">帳號</label>
            <input pInputText formControlName="user_name" id="user_name"
                   [ngClass]="{'ng-dirty':login_form.controls['user_name'].hasError('required') && login_form.controls['user_name'].touched}"/>
          </div>
          <div class="flex flex-column col-10 gap-2">
            <label class="text-cyan-900">密碼</label>
            <p-password formControlName="password" [feedback]="false" [toggleMask]="true" styleClass="w-full"
                        inputStyleClass="w-full" id="password"
                        [ngClass]="{'ng-dirty':login_form.controls['password'].hasError('required') && login_form.controls['password'].touched}">
            </p-password>
          </div>
        </div>
        <div class="field flex justify-content-center mb-4">
          <p-button label="登入" styleClass="flex justify-content-center align-items-center mt-4 login-btn"
                    routerLink="/main" [loading]="loading"
                    (click)="login()"></p-button>
        </div>
      </form>
    </div>
  </div>
  <div class="box-info">
    <div class="p-0 mt-1">
      <p-button icon="pi pi-times" (click)="closeLoginInfo()"
                styleClass="p-button-rounded p-button-secondary p-button-text b-info"></p-button>
    </div>
    <div class="p-0 mt-1">
      <p-button label="忘記密碼" (click)="goToPasswordReset()"
                styleClass="text-cyan-900 p-button-text mb-2 col-12"></p-button>
    </div>
    <div class="p-0 mt-1">
      <p-button label="聯絡我們" styleClass="p-button-secondary p-button-text mb-2 col-12"></p-button>
    </div>
    <div class="p-0 mt-4 flex justify-content-center">
              <span class="text-xs">未註冊帳號?
                <button class="text-button text-cyan-600 font-medium" routerLink="/register">註冊</button>
              </span>
    </div>
  </div>
</div>
<p-dialog [(visible)]="isVerify" [style]="{width: '30vw'}" [modal]="true"
          (keydown.enter)="verify()" [closable]="false"
          transitionOptions="0ms cubic-bezier(0, 0, 0, 0)">
  <div class="grid flex justify-content-center align-items-center" [class]="dialogClass">
    <div class="col-12 flex justify-content-end">
      <p-button icon="pi pi-times" (click)="closeVerify()"
                styleClass="p-button-rounded p-button-secondary p-button-text"></p-button>
    </div>
    <div class="col-12 flex justify-content-center align-items-center p-3">
      <span class="text-xl font-bold">多重驗證</span>
    </div>
    <div class="col-12 flex justify-content-center align-items-center">
      <i class="bg-blue-100 border-circle w-5rem h-5rem flex justify-content-center align-items-center">
        <i class="pi pi-lock text-blue-500 text-5xl"></i>
      </i>
    </div>
    <div class="col-12 flex justify-content-center align-items-center gap-2">
      <!--<input pInputText pKeyFilter="int" formControlName="passcode"-->
      <!--[ngClass]="{'ng-dirty':verify_form.controls['passcode'].hasError('required') &&-->
      <!--verify_form.controls['passcode'].touched}"/>-->
      <input #input0 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[0]"
             [ngClass]="code[0]?'border-blue-500':null"
             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 0)" (paste)="onPaste($event, 0)">
      <input #input1 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[1]"
             [ngClass]="code[1]?'border-blue-500':null"
             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 1)" (paste)="onPaste($event, 1)">
      <input #input2 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[2]"
             [ngClass]="code[2]?'border-blue-500':null"
             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 2)" (paste)="onPaste($event, 2)">
      <input #input3 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[3]"
             [ngClass]="code[3]?'border-blue-500':null"
             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 3)" (paste)="onPaste($event, 3)">
      <input #input4 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[4]"
             [ngClass]="code[4]?'border-blue-500':null"
             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 4)" (paste)="onPaste($event, 4)">
      <input #input5 type="tel" maxlength="1" class="text-blue-500 verify w-1" [(ngModel)]="code[5]"
             [ngClass]="code[5]?'border-blue-500':null"
             (keypress)="numberOnly($event)" (keyup)="onKeyup($event, 5)" (paste)="onPaste($event, 5)">
    </div>
    <div class="col-12 flex justify-content-center align-items-center">
      <p-button styleClass="mt-2 text-sm p-button-sm" label="OTP驗證" (click)="verify()"></p-button>
    </div>
    <ng-container *ngIf="verifyMethod===1">
      <div class="col-12 flex justify-content-center align-items-center">
        <p-button styleClass="text-xs p-button-sm p-button-info p-button-text"
                  [label]="countdown ? countdown + ' 秒' :'重新寄送驗證碼'"
                  [disabled]="idDisabled" [loading]="isCountdown" (onClick)="login()"></p-button>
      </div>
      <div class="col-12 text-center">
        <span class="text-xs">MFA 驗證碼已寄送至：<span class="text-xs font-bold">{{ email }}</span></span>
      </div>
    </ng-container>
    <ng-container *ngIf="verifyMethod===2">
      <div class="col-12 text-center">
        <span class="text-xs">請至認證應用程式獲取驗證碼</span>
      </div>
    </ng-container>
  </div>
  <div class="col-12 flex align-items-center cursor-pointer h-3rem mt-2 hover:surface-50 border-round"
       *ngIf="hasAnother" (click)="isAnother=true;isVerify=false">
    <div class="justify-content-start pi pi-chevron-left"></div>
    <div class="mx-auto">
      使用其他驗證方式
    </div>
  </div>
</p-dialog>
<p-dialog [(visible)]="isAnother" [closable]="false"
          transitionOptions="0ms cubic-bezier(0, 0, 0, 0)">
  <div class="grid flex justify-content-center align-items-center fadeinleft animation-duration-100">
    <div class="col-12 flex justify-content-center align-items-center mb-5">
      <span class="text-xl font-bold mx-auto">多重驗證</span>
      <div class="justify-content-end">
        <p-button icon="pi pi-times" (click)="closeVerify()"
                  styleClass="p-button-rounded p-button-secondary p-button-text"></p-button>
      </div>
    </div>
    <div class="col-12 flex align-items-center border-1 border-round border-solid border-500 cursor-pointer
          h-3rem mb-3 hover:surface-50 border-round"
         (click)="changeTo(2)">
      <div class="mx-auto">
        使用驗證應用程式
      </div>
      <div class="justify-content-end pi pi-chevron-right"></div>
    </div>
    <div class="col-12 flex align-items-center border-1 border-round border-solid border-500 cursor-pointer
          h-3rem hover:surface-50 border-round"
         (click)="changeTo(1)">
      <div class="mx-auto">
        使用 Email 驗證
      </div>
      <div class="justify-content-end pi pi-chevron-right"></div>
    </div>
  </div>
</p-dialog>
