import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GanttService} from "../../services/gantt.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [MessageService]
})
export class PasswordResetComponent implements OnInit{

  token: any;
  userDomain!: string;
  userPort!: string;
  userName!: string;
  userEmail!: string;
  forgot_form: FormGroup;
  reset_form: FormGroup;
  enableToken = false;
  isSending = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ganttServ: GanttService,
    private authServ: AuthService,
    private fb: FormBuilder,
    private messageServ: MessageService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
    this.forgot_form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.reset_form = this.fb.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*\\d).{8,}$')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*\\d).{8,}$')]],
    });
  }

  ngOnInit() {
    const {hostname, port} = this.authServ.getUserDomain();
    this.userDomain = hostname;
    this.userPort = port;
    if (this.token) {
      this.authServ.getCurrentUser(this.token).subscribe({
        next: res => {
          this.userName = res.body.user_name;
          this.enableToken = true;
        },
        error: err => {
          console.log('err', err);
          if (err.status === 401) {
            this.router.navigate(['/password_reset']);
          }
        }
      });
    }
  }

  forgot() {
    // 檢查資料是否填寫完畢
    const controls = this.forgot_form.controls;
    const requiredFields = ['email'];
    let scrollToField: any;
    for (const field of requiredFields) {
      if (controls[field].hasError('required')) {
        controls[field].markAsDirty();
        if (!scrollToField) {
          scrollToField = field;
        }
      }
    }
    if (scrollToField) {
      const element = document.getElementById(scrollToField);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
      return;
    }
    let body = {
      email: this.forgot_form.value.email,
      domain: this.userDomain,
      port: this.userPort
    }
    this.authServ.forgot(body).subscribe({
      next: res => {
        console.log('res', res);
        this.userEmail = res.body;
        this.isSending = true;
      },
      error: err => {
        console.log('err', err);
      }
    })
  }

  reset() {
    // 檢查資料是否填寫完畢
    const controls = this.reset_form.controls;
    const requiredFields = ['password', 'confirmPassword'];
    let scrollToField: any;
    for (const field of requiredFields) {
      if (controls[field].hasError('required')) {
        controls[field].markAsDirty();
        if (!scrollToField) {
          scrollToField = field;
        }
      }
    }
    if (scrollToField) {
      const element = document.getElementById(scrollToField);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
      return;
    }
    let body = {
      password: this.reset_form.value.password,
    }
    this.authServ.resetPassword(body, this.token).subscribe({
      next: res => {
        console.log('res', res);
        this.messageServ.add({severity: 'success', summary: '成功', detail: '已重設密碼！'});
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1000);
      },
      error: err => {
        console.log('err', err);
      }
    })
  }
}
