import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";
import {ApiResponse} from "../models/api-response.interface";

const AUTH_API = `${environment.apiUrl}/${environment.projectCode}/${environment.apiVersion}`;
const userUrl = `${AUTH_API}/users`;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private router: Router) {
  }

  // 登入
  login(body: any): Observable<any> {
    const url = `${AUTH_API}/login`;
    return this.http.post(url, body);
  }

  verify(body: any): Observable<any> {
    const url = `${AUTH_API}/verify`;
    return this.http.post(url, body, httpOptions);
  }

  forgot(body: any): Observable<any> {
    const url = `${AUTH_API}/forget-password`;
    return this.http.post(url, body, httpOptions);
  }

  // 取得使用者網域
  getUserDomain(): { hostname: string, port: string } {
    const url = new URL(window.location.href);
    return {
      hostname: url.hostname,
      port: url.port
    };
  }

  // 登出
  logout(): void {
    window.sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  register(body: any): Observable<any> {
    const url = `${AUTH_API}/register`;
    return this.http.post(url, body);
  }

  refreshToken(token: string) {
    const url = `${AUTH_API}/refresh`;
    return this.http.post(url, {refresh_token: token}, httpOptions);
  }

  getCurrentUser(token?: string): Observable<ApiResponse> {
    if (token) {
      const headers = new HttpHeaders().set('Authorization', token);
      return this.http.get<any>(`${userUrl}/current-user`, {headers: headers});
    } else {
      return this.http.get<any>(`${userUrl}/current-user`);
    }

  }

  getUsersFilter(page: number = 1, limit: number = 20, body?: any): Observable<ApiResponse> {
    body = {
      filter: {
        email: "",
        id: "",
        name: "",
        phone_number: "",
        resource_uuid: "",
        role_id: "",
        user_name: ""
      }
    }
    return this.http.post<any>(`${userUrl}/list?page=${page}&limit=${limit}`, body);
  }

  getUsersNoPagination(): Observable<ApiResponse> {
    return this.http.get<any>(`${userUrl}`);
  }

  getUserById(id: string): Observable<ApiResponse> {
    return this.http.get<any>(`${userUrl}/${id}`);
  }

  // 啟用使用者
  activateUser(token: string): Observable<ApiResponse> {
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.post<any>(`${userUrl}/enable/current-user`, {}, {headers: headers});
  }

  editUserById(id: string, body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${userUrl}/${id}`, body);
  }

  checkDuplicate(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${userUrl}/check-duplicate`, body);
  }

  editUser(body: any): Observable<ApiResponse> {
    return this.http.patch<any>(`${userUrl}/current-user`, body);
  }

  editEmail(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${userUrl}/change-email/current-user`, body);
  }

  verifyEmail(token: string): Observable<ApiResponse> {
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.post<any>(`${userUrl}/verify-email/current-user`, {}, {headers: headers});
  }

  removeUser(id: string): Observable<ApiResponse> {
    return this.http.delete<any>(`${userUrl}/${id}`);
  }

  resetPassword(body: any, token: string): Observable<ApiResponse> {
    const headers = new HttpHeaders().set('Authorization', token);
    return this.http.post<any>(`${userUrl}/reset-password/current-user`, body, {headers: headers});
  }

  authenticator(body: any): Observable<ApiResponse> {
    return this.http.post<any>(`${userUrl}/authenticator/current-user`, body);
  }
}
