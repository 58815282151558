<p-toast></p-toast>
<div class="h-screen flex justify-content-center align-items-center p-3">
  <ng-container *ngIf="!token">
    <div class="grid w-6">
      <div class="col-12 flex justify-content-center">
        <i class="bg-blue-100 border-circle w-5rem h-5rem flex justify-content-center align-items-center">
          <i class="pi pi-key text-blue-500 text-5xl"></i>
        </i>
      </div>
      <div class="col-12 flex justify-content-center">
        <span class="text-xl font-bold">重設密碼</span>
      </div>
      <div *ngIf="isSending" class="col-12 flex justify-content-center">
        <span class="text-base">重設密碼信件已寄出，請至<span class="font-bold"> {{ userEmail }} </span>收取信件</span>
      </div>
      <div class="col-12">
        <form [formGroup]="forgot_form" id="forgot_form">
          <div class="flex flex-column gap-2 col-12">
            <label>電子郵件</label>
            <input pInputText formControlName="email" id="email"
                   [ngClass]="{'ng-dirty':forgot_form.controls['email'].touched && (
                   forgot_form.controls['email'].hasError('required') ||
                   forgot_form.controls['email'].hasError('email'))}"/>
            <p-message severity="error" text="請輸入正確格式" *ngIf="forgot_form.controls['email'].hasError('email')"></p-message>
          </div>
        </form>
      </div>
      <div class="col-12">
        <p-button styleClass="col-12" label="確認" (click)="forgot()"></p-button>
      </div>
      <div class="col-12 flex justify-content-center">
        <p-button icon="pi pi-arrow-left" label="返回登入頁" routerLink="/login"
                  styleClass="p-button-secondary p-button-text"></p-button>
      </div>
      <!--        <p-dialog header="Header" [(visible)]="isSending" [style]="{ width: '30vw' }">-->
      <!--          <ng-template pTemplate="header">-->
      <!--            <span class="text-xl font-bold">已寄出</span>-->
      <!--          </ng-template>-->
      <!--          <span class="text-base">重設密碼信件已寄出，請至<span class="font-bold"> {{userEmail}} </span>收取信件</span>-->
      <!--          <ng-template pTemplate="footer">-->
      <!--            <p-button icon="pi pi-check" (click)="isSending = false" label="確定" styleClass="p-button-text"></p-button>-->
      <!--          </ng-template>-->
      <!--        </p-dialog>-->
    </div>
  </ng-container>
  <ng-container *ngIf="enableToken">
    <div class="grid">
      <div class="col-12 flex justify-content-center">
        <span class="text-xl font-bold">您好，{{ userName }}！</span>
      </div>
      <div class="col-12 flex justify-content-center">
        <span class="text-base">在此重設您的密碼</span>
      </div>
      <div class="col-12 flex justify-content-center">
        <form [formGroup]="reset_form" id="reset_form">
          <div class="grid">
            <div class="col-12 flex flex-column gap-2">
              <label><span class="text-red-600">*</span>密碼</label>
              <p-password formControlName="password" [feedback]="false" [toggleMask]="true" styleClass="w-full"
                          inputStyleClass="w-full"
                          [ngClass]="{'ng-dirty':reset_form.controls['password'].touched && (
                          reset_form.controls['password'].hasError('required') ||
                          reset_form.controls['password'].hasError('pattern'))}">
              </p-password>
              <p-message severity="success"
                         *ngIf="reset_form.controls['password'].valid"
                         text="至少8個字符包含英文數字"></p-message>
              <p-message severity="info"
                         *ngIf="reset_form.controls['password'].hasError('required')"
                         text="至少8個字符包含英文數字"></p-message>
              <p-message severity="error"
                         *ngIf="reset_form.controls['password'].hasError('pattern')"
                         text="至少8個字符包含英文數字"></p-message>
            </div>
            <div class="col-12 flex flex-column gap-2 w-full">
              <label><span class="text-red-600">*</span>確認密碼</label>
              <p-password formControlName="confirmPassword" [feedback]="false" [toggleMask]="true" styleClass="w-full"
                          inputStyleClass="w-full"
                          [ngClass]="{'ng-dirty':reset_form.controls['confirmPassword'].touched && (
                          reset_form.controls['confirmPassword'].hasError('required') ||
                          reset_form.controls['confirmPassword'].hasError('pattern'))}">
              </p-password>
              <p-message
                *ngIf="reset_form.controls['confirmPassword'].touched && reset_form.controls['password'].value !== reset_form.controls['confirmPassword'].value"
                severity="error" text="密碼不相同！"></p-message>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 flex justify-content-center">
        <p-button styleClass="flex justify-content-center w-17rem mt-2" label="確認" (click)="reset()"></p-button>
      </div>
    </div>
  </ng-container>
</div>
