import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GanttService} from "../../services/gantt.service";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {TokenStorageService} from "../../services/token-storage.service";
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  register_form: FormGroup;
  userDomain!: string;
  userPort!: string;
  success = false;
  email!: string;
  isLoading = false;
  isUserDuplicate = false;
  isEmailDuplicate = false;


  constructor(
    private fb: FormBuilder,
    private ganttServ: GanttService,
    private authServ: AuthService,
    private router: Router,
    private messageService: MessageService,
    private tokenStorage: TokenStorageService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.register_form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*\\d).{8,}$')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*\\d).{8,}$')]],
      email: ['', [Validators.required, Validators.email]],
    })
  }

  ngOnInit() {
    const {hostname, port} = this.authServ.getUserDomain();
    this.userDomain = hostname;
    this.userPort = port;
  }

  register() {
    this.isLoading = true;
    // 檢查資料是否填寫完畢
    const controls = this.register_form.controls;
    const requiredFields = ['user_name', 'password', 'confirmPassword', 'email'];
    let scrollToField: any;
    for (const field of requiredFields) {
      if (controls[field].hasError('required')) {
        controls[field].markAsDirty();
        if (!scrollToField) {
          scrollToField = field;
        }
      }
    }
    if (scrollToField) {
      this.isLoading = false;
      const element = document.getElementById(scrollToField);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
      return;
    }
    let body = {
      user_name: this.register_form.controls['user_name'].value,
      email: this.register_form.controls['email'].value,
      password: this.register_form.controls['password'].value,
      domain: this.userDomain,
      port: this.userPort ? this.userPort : null
    }
    this.authServ.register(body).subscribe({
      next: res => {
        this.isLoading = false;
        this.email = res.body;
        this.success = true;
      },
      error: err => {
        console.log(err)
        this.isLoading = false;
      }
    })
  }

  checkDuplicate(type: string) {
    let body: any;
    if (type === 'user_name' && this.register_form.controls['user_name'].value) {
      body = {
        filter: {
          user_name: this.register_form.controls['user_name'].value,
        }
      }
    } else if (type === 'email' && this.register_form.controls['email'].value) {
      body = {
        filter: {
          email: this.register_form.controls['email'].value,
        }
      }
    } else {
      return;
    }
    this.authServ.checkDuplicate(body).subscribe({
      next: res => {
        if (type === 'user_name') {
          this.isUserDuplicate = res.body.is_duplicate;
        } else if (type === 'email') {
          this.isEmailDuplicate = res.body.is_duplicate;
        }
      },
      error: err => {
        console.log(err)
      }
    })
  }
}
