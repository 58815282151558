import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './views/error/error.component';
import {LoginComponent} from './components/login/login.component';
import {PasswordResetComponent} from "./components/password-reset/password-reset.component";
import {AuthGuard} from "./shared/guard/auth.guard";
import {UserInfoGuard} from "./shared/guard/user-info.guard";
import {RegisterComponent} from './components/register/register.component';
import { ActivateComponent } from './components/activate/activate.component';
import { EmailVerifiedSuccessComponent } from './components/email-verified-success/email-verified-success.component';
import { ExpiredComponent } from './components/expired/expired.component';


const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'password_reset', component: PasswordResetComponent},
  {path: 'password_reset/:token', component: PasswordResetComponent},
  {path: 'activate/:token', component: ActivateComponent},
  {path: 'email_verify/:token', component: EmailVerifiedSuccessComponent},
  {path: 'expired/:type', component: ExpiredComponent},
  {
    path: '',
    loadChildren: () => import('./views/container/container.module').then(m => m.ContainerModule),
    canActivate: [UserInfoGuard]
  },
  {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
