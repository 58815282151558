import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenubarModule} from 'primeng/menubar';
import {MenuModule} from 'primeng/menu';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SplitButtonModule} from 'primeng/splitbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {FileUploadModule} from 'primeng/fileupload';
import {TableModule} from 'primeng/table';
import {InputTextModule} from "primeng/inputtext";
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToastModule} from 'primeng/toast';
import {MessagesModule} from 'primeng/messages';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TabViewModule} from "primeng/tabview";
import {DropdownModule} from "primeng/dropdown";
import {CalendarModule} from "primeng/calendar";
import {SidebarModule} from 'primeng/sidebar';
import {StepsModule} from 'primeng/steps';
import {CardModule} from "primeng/card";
import {PasswordModule} from "primeng/password";
import {AnimateModule} from 'primeng/animate';
import {ColorPickerModule} from "primeng/colorpicker";
import {MessageModule} from "primeng/message";
import {MessageService} from 'primeng/api';
import {DividerModule} from "primeng/divider";
import {AutoCompleteModule} from "primeng/autocomplete";
import {DataViewModule} from "primeng/dataview";
import {TagModule} from "primeng/tag";
import {KnobModule} from "primeng/knob";
import {ChipModule} from 'primeng/chip';
import {TabMenuModule} from 'primeng/tabmenu';
import {CheckboxModule} from 'primeng/checkbox';
import {BadgeModule} from "primeng/badge";
import {AvatarModule} from 'primeng/avatar';
import {OverlayPanelModule} from 'primeng/overlaypanel';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    MenubarModule,
    MenuModule,
    PanelMenuModule,
    SplitButtonModule,
    MultiSelectModule,
    FileUploadModule,
    TableModule,
    InputTextModule,
    ToggleButtonModule,
    ToastModule,
    MessagesModule,
    ConfirmDialogModule,
    DialogModule,
    InputNumberModule,
    InputSwitchModule,
    CalendarModule,
    TabViewModule,
    DropdownModule,
    SidebarModule,
    StepsModule,
    CardModule,
    PasswordModule,
    AnimateModule,
    ColorPickerModule,
    MessageModule,
    DividerModule,
    AutoCompleteModule,
    DataViewModule,
    TagModule,
    KnobModule,
    ChipModule,
    TabMenuModule,
    CheckboxModule,
    BadgeModule,
    AvatarModule,
    OverlayPanelModule
  ],
  providers: [MessageService]
})
export class PrimengModule {
}
