import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Ej2Module} from './ej2/ej2.module';
import {PrimengModule} from './primeng/primeng.module';
import { FormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {environment} from "../../environments/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    Ej2Module,
    PrimengModule,
    RecaptchaV3Module,
    FormsModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
})
export class SharedModule {
}
