export const environment = {
  production: false,
  // apiUrl: ' https://api.t.hamastar.net',
  apiUrl: ' https://pmip.t.api.likbox.com',
  projectCode: 'hta-gantt',
  apiVersion: 'v1.0',
  recaptcha: {
    siteKey: '6LfgzyUpAAAAAClra_7EzCzHO-juc5RjugU08YUp'
  },
  region: 'ap-southeast-1',
  accessKeyId: "AKIAWI64IZ74HKEYR26P",
  secretAccessKey: "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"
};
