import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {TokenStorageService} from "../../services/token-storage.service";
import {GanttService} from "../../services/gantt.service";
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService, ReCaptchaV3Service]
})
export class LoginComponent implements OnInit {
  @ViewChild('input0') input0: ElementRef | undefined;
  @ViewChild('input1') input1: ElementRef | undefined;
  @ViewChild('input2') input2: ElementRef | undefined;
  @ViewChild('input3') input3: ElementRef | undefined;
  @ViewChild('input4') input4: ElementRef | undefined;
  @ViewChild('input5') input5: ElementRef | undefined;

  login_form: FormGroup;
  roles!: string;
  resources: any[] = [];
  code: (number | null)[] = [];
  email!: string;
  userDomain!: string;

  [key: string]: any;

  countdown: number = 0;
  isCountdown = false;
  idDisabled = true;
  title!: string;
  loading: boolean = false;
  isVerify = false;
  verifyMethod: number = 1; // email:1 authy:2
  isAnother: boolean = false;
  hasAnother: boolean = false;
  dialogClass: string = '';

  // giveAnimation!: string;

  constructor(
    private fb: FormBuilder,
    private ganttServ: GanttService,
    private authServ: AuthService,
    private router: Router,
    private messageService: MessageService,
    private tokenStorage: TokenStorageService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.login_form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  ngOnInit() {
    // this.getResouces();
    // this.login_form.controls['password'].disable();
    // this.login_form.controls['confirmPassword'].disable();
    const {hostname, port} = this.authServ.getUserDomain();
    this.title = this.getTitle(hostname);
    this.userDomain = hostname;
  }

  login(changeTo?: number): void {
    this.loading = true;
    this.email = '';
    this.hasAnother = false;
    // 檢查資料是否填寫完畢
    const controls = this.login_form.controls;
    const requiredFields = ['user_name', 'password'];
    let scrollToField: any;
    for (const field of requiredFields) {
      if (controls[field].hasError('required')) {
        controls[field].markAsDirty();
        if (!scrollToField) {
          scrollToField = field;
        }
      }
    }
    if (scrollToField) {
      const element = document.getElementById(scrollToField);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
      this.loading = false;
      return;
    }
    let body = {
      user_name: this.login_form.controls['user_name'].value,
      password: this.login_form.controls['password'].value,
      change_to: changeTo ? changeTo : null
    }
    this.authServ.login(body).subscribe({
      next: res => {
        if (res.body === "Please use authenticator to login.") {
          this.verifyMethod = 2;
          this.hasAnother = true;
        } else {
          this.verifyMethod = 1;
          this.email = res.body;
          this.isCountdown = true;
          this.countdown = 30;
          const countdownInterval = setInterval(() => {
            if (this.countdown !== null && this.countdown > 0) {
              this.countdown--;
            } else {
              this.isCountdown = false;
              this.idDisabled = false;
              clearInterval(countdownInterval);
            }
          }, 1000);
        }
        this.loading = false;
        this.isVerify = true;
      },
      error: err => {
        this.loading = false;
        // 帳密錯誤
        if (err.status === 403) {
          this.showError('失敗', '帳號或密碼錯誤！');
        }
        if (err.status === 500) {
          this.showError('發生錯誤', '請聯絡管理員！');
        }
      }
    });
  }

  changeTo(verifyMethod: number) {
    this.verifyMethod = verifyMethod;
    this.dialogClass = 'fadeinright animation-duration-100';
    this.isAnother = false;
    this.isVerify = true;
    if (verifyMethod === 1 && this.countdown === 0) {
      this.login(verifyMethod);
    }
  }

  closeVerify() {
    this.isVerify = false;
    this.isAnother = false;
    this.countdown = 0;
    this.isCountdown = false;
    this.idDisabled = true;
    this.login_form.reset();
  }

  verify() {
    let body = {
      passcode: this.code.join(''),
      user_name: this.login_form.controls['user_name'].value,
    }
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        console.debug(`Token [${token}] generated`);
      });
    this.authServ.verify(body).subscribe({
      next: res => {
        this.tokenStorage.saveToken(res.body.access_token);
        this.tokenStorage.saveRefreshToken(res.body.refresh_token);
        this.tokenStorage.saveComplete(res.body.is_complete);
        this.tokenStorage.saveRole(res.body.role);
        this.saveUser();
        this.showSuccess();
        if (res.body.is_complete) {
          this.router.navigate(['/main']);
        } else {
          this.router.navigate(['/user-info']);
        }
      },
      error: err => {
        console.log(err)
        if (this.countdown === 0) {
          this.showError('失敗', '驗證碼已過期！');
        }
        this.showError('失敗', '驗證碼錯誤！');
      }
    })
  }

  saveUser() {
    this.authServ.getCurrentUser().subscribe({
      next: res => {
        this.tokenStorage.saveUser(res.body.name);
        this.roles = res.body.role;
      },
      error: err => {
        console.log(err)
      }
    })
  }

  // 限制只能輸入數字
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onKeyup(event: KeyboardEvent, index: number) {
    if (event.key >= '0' && event.key <= '9') {
      // 如果輸入的是數字，則跳轉到下一個輸入框
      if (index < 5) {
        this[`input${index + 1}`].nativeElement.focus();
      }
    } else if (event.key === 'Backspace') {
      // 如果按下了Backspace鍵，則跳轉到上一個輸入框並刪除該輸入框的值
      if (index > 0) {
        this.code[index - 1] = null;
        this[`input${index - 1}`].nativeElement.focus();
      }
    }
  }

  onPaste(event: ClipboardEvent, index: number) {
    let pastedText = event.clipboardData?.getData('text');
    if (pastedText) {
      let pastedArray = pastedText.split('');
      for (let i = index; i < 6; i++) {
        this.code[i] = pastedArray[i - index] ? parseInt(pastedArray[i - index], 10) : null;
      }
    }
  }

  goToPasswordReset() {
    this.router.navigate(['/password_reset']);
  }

  showSuccess() {
    this.messageService.add({severity: 'success', summary: '歡迎', detail: '成功登入！'});
  }

  showError(summary: string, detail: string) {
    this.messageService.add({severity: 'error', summary: `${summary}`, detail: `${detail}`});
  }

  // clickHaveAccount() {
  //   this.isLogin = true;
  //   this.giveAnimation = "flipright animation-duration-500";
  // }

  // getResouces() {
  //   this.ganttServ.getResources().subscribe({
  //     next: res => {
  //       this.resources = res.body.resources;
  //       console.log(res)
  //     },
  //     error: err => {
  //       console.log(err)
  //     }
  //   });
  // }


  // 開啟HTML更多選項
  openLoginInfo(): void {
    const elements = document.querySelectorAll('.b-form, .box-form, .box-info');
    (elements[0] as HTMLElement).style.left = "-20%";
    (elements[1] as HTMLElement).style.left = "-20%";
    (elements[2] as HTMLElement).style.right = "-18%";
  }

  // 關閉HTML更多選項
  closeLoginInfo(): void {
    const elements = document.querySelectorAll('.b-form, .box-form, .box-info');
    (elements[0] as HTMLElement).style.left = "0%";
    (elements[1] as HTMLElement).style.left = "0%";
    (elements[2] as HTMLElement).style.right = "3%";
  }

  getTitle(hostname: string): string {
    switch (hostname) {
      case 'localhost':
        return 'Local PMIS';
      case 'd.pmip.likbox.com':
        return 'Develop PMIS';
      case 'pp.pmip.likbox.com':
        return 'Test PMIS';
      case 'pp.pm.hta365.net':
        return 'HTA Test PMIS';
      case 'pm.hta365.net':
        return 'HTA PMIS';
      default:
        return 'PMIS';
    }
  }
}
