<div class="h-screen w-screen flex justify-content-center align-items-center">
  <div *ngIf="isLoading" class="loading-screen">
    <span class="loader"></span>
  </div>
  <p-card styleClass="pt-5" [style]="{ width: '70vw' }" [formGroup]="register_form" id="register_form">
    <div class="grid flex justify-content-center">
      <div class="col-8 px-2 flex justify-content-start">
        <span class="font-bold text-4xl">註冊帳號</span>
      </div>
      <div class="col-8 flex justify-content-center">
        <div class="grid mt-2 gap-3">
          <div class="flex flex-column col-12 gap-2">
            <label class="text-cyan-900"><span class="text-red-600">*</span>帳號</label>
            <div class="flex flex-row align-items-center col-12 gap-2 input-flex">
              <input pInputText formControlName="user_name"
                     [ngClass]="{'ng-dirty':register_form.controls['user_name'].hasError('required') &&
                     register_form.controls['user_name'].touched, 'ng-invalid':isUserDuplicate}" (input)="checkDuplicate('user_name')"/>
              <p-message severity="success" text="此使用者名稱可使用" *ngIf="!isUserDuplicate && register_form.controls['user_name'].value"></p-message>
              <p-message severity="error" text="此使用者名稱已被其他使用者註冊" *ngIf="isUserDuplicate"></p-message>
            </div>
          </div>
          <div class="flex flex-column col-12 gap-2">
            <label class="text-cyan-900"><span class="text-red-600">*</span>電子郵件</label>
            <div class="flex flex-row align-items-center col-12 gap-2 input-flex">
              <input pInputText formControlName="email"
                     [ngClass]="{'ng-dirty':register_form.controls['email'].hasError('required') &&
                     register_form.controls['email'].touched,'ng-invalid':isEmailDuplicate}"
                     (input)="register_form.controls['email'].valid && checkDuplicate('email')"/>
              <p-message severity="success" text="此電子郵件可使用" *ngIf="!isEmailDuplicate && !register_form.controls['email'].hasError('email')
              && register_form.controls['email'].value"></p-message>
              <p-message severity="error" text="該電子郵件已被註冊" *ngIf="isEmailDuplicate"></p-message>
              <p-message severity="error" text="請輸入正確格式" *ngIf="register_form.controls['email'].hasError('email')"></p-message>
            </div>
          </div>
          <div class="flex flex-column col-12 gap-2">
            <label class="text-cyan-900"><span class="text-red-600">*</span>密碼</label>
            <div class="flex flex-row align-items-center col-12 gap-2 input-flex">
              <p-password formControlName="password" [feedback]="false" [toggleMask]="true" styleClass="w-full"
                          inputStyleClass="w-full"
                          [ngClass]="{'ng-dirty':register_form.controls['password'].touched && (
                          register_form.controls['password'].hasError('required') ||
                          register_form.controls['password'].hasError('pattern'))}">
              </p-password>
              <p-message severity="success"
                         *ngIf="register_form.controls['password'].valid"
                         text="至少8個字符包含英文數字"></p-message>
              <p-message severity="info"
                         *ngIf="register_form.controls['password'].hasError('required')"
                         text="至少8個字符包含英文數字"></p-message>
              <p-message severity="error"
                         *ngIf="register_form.controls['password'].hasError('pattern')"
                         text="至少8個字符包含英文數字"></p-message>
            </div>
          </div>
          <div class="flex flex-column col-12 gap-2">
            <label><span class="text-red-600">*</span>確認密碼</label>
            <div class="flex flex-row align-items-center col-12 gap-2 input-flex">
              <p-password formControlName="confirmPassword" [feedback]="false" [toggleMask]="true" styleClass="w-full"
                          inputStyleClass="w-full"
                          [ngClass]="{'ng-dirty':register_form.controls['confirmPassword'].touched && (
                          register_form.controls['confirmPassword'].hasError('required') ||
                          register_form.controls['confirmPassword'].hasError('pattern'))}">
              </p-password>
              <p-message severity="error" text="密碼不相同！"
                         *ngIf="register_form.controls['confirmPassword'].touched && register_form.controls['password'].value !== register_form.controls['confirmPassword'].value">
              </p-message>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 flex justify-content-center">
      <p-button label="註冊" styleClass="flex justify-content-center align-items-center mt-4 login-btn"
                (click)="register()"></p-button>
    </div>
    <div class="col-12 flex justify-content-center">
        <span class="text-xs">已擁有帳號?
            <button class="text-button text-cyan-600" routerLink="/login">登入</button>
        </span>
    </div>
  </p-card>
</div>
<p-dialog [(visible)]="success" [closable]="false" [style]="{width: '25vw'}" [modal]="true">
  <div class="grid gap-5">
    <div class="col-12 flex justify-content-center">
      <span class="font-bold text-2xl">註冊成功</span>
    </div>
    <div class="col-12 flex justify-content-center">
      <span class="pi pi-check-circle font-bold register-success-icon"></span>
    </div>
    <div class="col-12 flex justify-content-center">
      <span class="text-xs">請至 <span
        class="text-xs font-bold">{{ email }}</span> 收取驗證信件，並點擊連結以完成註冊。</span>
    </div>
    <div class="col-12 flex justify-content-center">
      <p-button label="確認" styleClass="flex justify-content-center align-items-center login-btn"
                (click)="success=false"></p-button>
    </div>
  </div>
</p-dialog>
