import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {

  type: any;
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.type = this.route.snapshot.paramMap.get('type');
    console.log('type', this.type);
  }

  ngOnInit() {
    if (this.type === 'activate') {
      this.isLoading = false;
    } else if (this.type === 'user') {
      this.isLoading = false;
    }
  }
}
