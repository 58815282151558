import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GanttService} from "../../services/gantt.service";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

  token: any;
  isLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ganttServ: GanttService,
    private authServ: AuthService,
    private router: Router,
    private messageService: MessageService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
  }

  ngOnInit() {
    if (this.token) {
      this.authServ.activateUser(this.token).subscribe({
        next: res => {
          this.isLoading = false;
          console.log('res', res);
        },
        error: err => {
          this.isLoading = false;
          console.log('err', err);
        }
      });
    }
  }
}
